import { useState } from 'react';

import simpletaskService from '../../service/simpletask.service';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

import AddDialog from './AddDialog';
import DeleteDialog from '../ui/DeleteDialog';

import { DataGridUI, ButtonUI } from '../Interface';
import { dataGridColumns } from '../../utils/helper';
import React from 'react';

const FormTable = ({ setMemberList, memberList, setRefetchTable, disabled, simpleTaskId }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [removeId, setRemoveId] = useState(null);
  const [addModalShow, setAddModalShow] = useState(false);

  const removeMember = (id) => {
    simpletaskService
      .deleteRespPersonFromList(id)
      .then(() => {
        addNotification({
          content: 'Sikeres törlés!',
          type: NotificationType.WARNING,
        });
        setMemberList((prev) => {
          return prev.filter((item) => item.respPersonListId !== id);
        });
        setRefetchTable(true);
        setShowDeleteDialog(false);
      })
      .finally(() => {
        // @TODO loader
      });
  };

  const deleteIcon = (disabled, params) => {
    return (
      <div
        disabled={disabled}
        className={disabled ? 'hidden' : 'block cursor-pointer'}
        onClick={(e) => {
          e.stopPropagation();
          setRemoveId(params.row.respPersonListId);
          setShowDeleteDialog(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-[20px] h-[20px]"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line> <line x1="6" y1="6" x2="18" y2="18"></line>{' '}
        </svg>
      </div>
    );
  };

  const columns = dataGridColumns([
    {
      field: 'userId',
      headerName: 'Azon.',
      flex: 0.5,
    },
    {
      field: 'userName',
      headerName: 'Név',
      flex: 1,
      valueGetter: (data) => {
        return data.row.user.userName ?? 'Nincs adat.';
      },
    },
    {
      field: 'roleName',
      headerName: 'Szerep',
      flex: 1,
      valueGetter: (params) => {
        return params.value ?? 'Nincs adat.';
      },
    },
    {
      field: 'removeTag',
      headerName: '',
      flex: 0.4,
      renderCell: (params) => {
        return deleteIcon(disabled, params);
      },
    },
  ]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Tagok</h1>
          <p className="mt-2 text-sm text-gray-700">Ebben a listában adja hozzá a további szükséges tagokat.</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <ButtonUI
            text="Hozzáadás"
            type="button"
            disabled={disabled}
            onClick={() => {
              setAddModalShow(true);
            }}
            className="bg-success block rounded-md px-3 disabled:cursor-not-allowed disabled:opacity-50 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          />
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <DataGridUI
                sx={{ height: 440, width: '100%' }}
                columns={columns}
                rows={memberList}
                getRowId={(row) => row.respPersonListId}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteDialog
        open={showDeleteDialog}
        setOpen={() => setShowDeleteDialog(false)}
        handleRemoveElement={() => removeMember(removeId)}
      />
      <AddDialog
        show={addModalShow}
        setMemberList={setMemberList}
        onHide={() => setAddModalShow(false)}
        simpleTaskId={simpleTaskId}
      />
    </div>
  );
};

export default FormTable;
