import React, { useState, useEffect } from 'react';
import { PieChart, BarChart } from '@mui/x-charts';
import dayjs from 'dayjs';

import { DatePickerUI } from '../Interface';
import { generateFilter } from '../../utils/helper';
import taskevaluationTaskService from '../../service/taskevaluationTask.service';
import { useLoader } from '../../provider/LoaderProvider';
import { ColorPicker } from '../../config/ColorPicker';

const TaskevaluationForm = () => {
  const { hideLoader, showLoader } = useLoader();
  const [chartData, setChartData] = useState([]);
  const [barChartData, setBarChartData] = useState({ xAxis: [], series: [] });
  const [filterFormState, setFilterFormState] = useState({
    startDate: dayjs().startOf('day'),
    endDate: dayjs().endOf('day'),
  });

  const [query, setQuery] = useState({
    filter: [],
  });

  const conditions = {
    gte: ['startDate'],
    lte: ['endDate'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (query.filter.length > 0) {
      getTasks();
    }
  }, [query]);

  const getTasks = () => {
    showLoader();
    taskevaluationTaskService
      .getTasks(query)
      .then((data) => {
        setChartData(data);
        const barChart = data.reduce(
          (acc, data) => {
            acc.xAxis.push(data.label);
            acc.series.push(data.value);
            return acc;
          },
          { xAxis: [], series: [] }
        );
        setBarChartData(barChart);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const chartColor = ColorPicker.slice(1).map((color) => color.colorCode);

  return (
    <div className="w-full h-full fixed bg-gray-200">
      <div className="flex justify-center gap-10 mt-2">
        <div>
          <DatePickerUI
            label={<span className="text-labelColor">Kezdő dátum</span>}
            value={filterFormState.startDate}
            onChange={(newDate) => {
              setFilterFormState((prevState) => ({
                ...prevState,
                startDate: newDate ? newDate.startOf('day') : null,
              }));
            }}
          />
        </div>
        <div>
          <DatePickerUI
            label={<span className="text-labelColor">Befejező dátum</span>}
            value={filterFormState.endDate}
            onChange={(newDate) => {
              setFilterFormState((prevState) => ({
                ...prevState,
                endDate: newDate ? newDate.endOf('day') : null,
              }));
            }}
          />
        </div>
      </div>
      <div className="flex justify-center w-full mt-20">
        <PieChart colors={chartColor} series={[{ data: chartData }]} width={800} height={500} />
        <BarChart
          sx={{
            '& .MuiBarElement-root': {
              width: '60px !important',
            },
          }}
          colors={chartColor}
          xAxis={[{ scaleType: 'band', data: barChartData.xAxis }]}
          series={[{ data: barChartData.series }]}
          width={700}
          height={500}
        />
      </div>
    </div>
  );
};

export default TaskevaluationForm;
