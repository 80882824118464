import React from 'react';
import { ErrorMessage } from 'formik';
import { FormHelperText } from '@mui/material';

const FormError = ({ message }) => {
  return (
    <FormHelperText style={{ margin: 0 }} error>
      {message}
    </FormHelperText>
  );
};

export default FormError;
