import React, { useState } from 'react';
import { CirclePicker } from 'react-color';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { ColorPicker } from '../../config/ColorPicker';

const ColorPickerUI = ({ color, changeColor, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const defaultColors = ColorPicker.map((colorItem) => colorItem.colorCode);

  const handlePopoverOpen = (event) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangeColor = (pickedColor) => {
    const foundColor = ColorPicker.find((colorItem) => colorItem.colorCode === pickedColor.hex);
    changeColor(foundColor);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'color-popover' : undefined;

  return (
    <>
      <div
        onClick={handlePopoverOpen}
        style={{ backgroundColor: color }}
        className="rounded-full shadow-[rgba(0,_0,_0,_0.54)_0px_1px_8px] w-[20px] h-[20px] hover:cursor-pointer"
      ></div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div className="p-4">
          <Typography>
            <CirclePicker color={color} onChange={handleChangeColor} colors={defaultColors} />
          </Typography>
        </div>
      </Popover>
    </>
  );
};

export default ColorPickerUI;
