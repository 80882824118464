import { DataGrid, huHU } from '@mui/x-data-grid';

const DataGridUI = (props) => {
  const dataGridStyles = {
    root: {
      height: 650,
      width: '100%',
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#B3C8D3',
      },
      '& .MuiDataGrid-row.Mui-selected:hover': {
        backgroundColor: '#B3C8D3',
      },
      '& .MuiDataGrid-row:nth-of-type(even)': {
        backgroundColor: '#EBEAEA',
      },
      '& .MuiDataGrid-row.Mui-selected:nth-of-type(even)': {
        backgroundColor: '#B3C8D3',
      },
      '& .MuiDataGrid-row:nth-of-type(odd)': {
        backgroundColor: '#F7F7F7',
      },
      '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd)': {
        backgroundColor: '#B3C8D3',
      },
    },
  };
  return (
    <DataGrid
      sx={dataGridStyles.root}
      localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
      pageSize={5}
      disableSelectionOnClick
      {...props}
    />
  );
};

export default DataGridUI;
