import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import InputUI from '../Interface/InputUI';
import AutoCompleteSelectUI from '../Interface/AutoCompleteSelectUI';
import TodoCard from './TodoCard';
import simpletaskService from '../../service/simpletask.service';
import { useLoader } from '../../provider/LoaderProvider';
import { generateFilter } from '../../utils/helper';

const TodoContent = ({ activeTab, type, tabId }) => {
  const [myTodo, setMyTodo] = useState([]);
  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 20,
        page: 0,
      },
    },
  });
  const [formVal, setFormVal] = useState({
    statusId: '',
    priorId: '',
    simpleTaskName: '',
  });
  const [defaultFilterFields, setDefaultFilterFields] = useState([]);

  const userObj = JSON.parse(localStorage.getItem('user'));

  const { showLoader, hideLoader } = useLoader();

  const conditions = {
    like: ['simpleTaskName'],
    gte: ['startDate'],
    lte: ['endDate'],
  };

  useEffect(() => {
    console.log(myTodo);
  }, [myTodo]);

  useEffect(() => {
    setMyTodo([]);

    const newFields = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    if (type === 'own') {
      newFields.push({ field: 'resposiblePersonId', value: userObj.userId });
    } else if (type === 'opened') {
      newFields.push({ field: 'resposiblePersonId', value: null });
      //@TODO telephely, szerep stb majd itt kell kezelni
    }

    setDefaultFilterFields(newFields);
  }, [activeTab]);

  useEffect(() => {
    if (Object.keys(query).length > 0 && activeTab === tabId && query.filter?.length > 0) {
      getList();
    }
  }, [query.pagination, query.sorting, query.filter]);

  useEffect(() => {
    const newFilter = generateFilter(formVal, conditions);
    setMyTodo([]);

    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilterFields, ...newFilter] };
    });
  }, [formVal, defaultFilterFields]);

  const getList = () => {
    showLoader();
    simpletaskService
      .getTasks(query)
      .then((data) => {
        setMyTodo((prev) => [...prev, ...data.rows]);
      })
      .finally(() => {
        // @TODO loader
        hideLoader();
      });
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-4 mx-4 md:w-3/4 lg:1/2  mx-auto">
        <div className="col-span-3 md:col-span-1">
          <InputUI
            variant="standard"
            label={<span className="text-labelColor">Keresés...</span>}
            onChange={(e) => {
              setFormVal((prev) => {
                return { ...prev, simpleTaskName: e.target.value };
              });
            }}
            value={formVal.simpleTaskName}
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <AutoCompleteSelectUI
            label={<span className="text-labelColor">Státusz</span>}
            onChange={(_e, newVal, reason) => {
              if (reason === 'clear') {
                setFormVal((prev) => {
                  return { ...prev, statusId: '' };
                });
              } else {
                setFormVal((prev) => {
                  return { ...prev, statusId: newVal?.value ?? '' };
                });
              }
            }}
            selectedValue={formVal.statusId}
            disabled={false}
            id={'selectedStatus'}
            table={['statusitems', 'status']}
            listType={{ id: 'itemId', name: 'itemName' }}
            joins={'statusitems.statusId = status.statusId'}
            conditions={`status.subMenuId = 6`}
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <AutoCompleteSelectUI
            label={<span className="text-labelColor">Prioritás</span>}
            onChange={(_e, newVal, reason) => {
              if (reason === 'clear') {
                setFormVal((prev) => {
                  return { ...prev, priorId: '' };
                });
              } else {
                setFormVal((prev) => {
                  return { ...prev, priorId: newVal?.value ?? '' };
                });
              }
            }}
            selectedValue={formVal.priorId}
            disabled={false}
            id={'selectedPrior'}
            table={['prioritylistitems ', 'priority']}
            listType={{ id: 'itemId', name: 'itemName' }}
            joins={'prioritylistitems.priorityId = priority.priorityId'}
            conditions={`priority.subMenuId = 6`}
            //TODO a submenuid paraméter legyen feldatok menüpont submenu id-je
          />
        </div>
      </div>
      <div className="sm:flex sm:justify-between"></div>
      <div className="mb-10">
        <div className="py-10 flex flex-wrap gap-7 justify-center">
          {myTodo.map((task, id) => {
            return <TodoCard task={task} setMyTodo={setMyTodo} />;
          })}
        </div>
      </div>
    </>
  );
};

export default TodoContent;
