import { useState } from 'react';
import FormTable from './FormTable';
import TabsUI from '../Interface/TabsUI';

export default function FormTabs(props) {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'Form elemek',
      value: 'formelemek',
      content: (
        <div>
          <FormTable
            itemId={props.itemId}
            statusId={props.statusId}
            disabled={props.disabled}
            statusList={props.statusList}
            setstatusList={props.setstatusList}
            setRefetchTable={props.setRefetchTable}
            viewField={props.viewField}
            selectedValues={props.selectedValues}
          />
        </div>
      ),
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
}
