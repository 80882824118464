import ButtonUI from './ButtonUI';

const FormHeaderUI = ({
  addClick,
  modClick,
  deleteClick,
  copyClick,
  addDisabled,
  modDisabled,
  deleteDisabled,
  copyDisabled,
}) => {
  return (
    <>
      <div className="flex gap-2 p-3 flex-wrap">
        {addClick && <ButtonUI className="bg-success" text="Hozzáadás" disabled={addDisabled} onClick={addClick} />}
        {modClick && <ButtonUI className="bg-warning" text="Szerkesztés" disabled={modDisabled} onClick={modClick} />}
        {deleteClick && (
          <ButtonUI className="bg-danger" text="Törlés" disabled={deleteDisabled} onClick={deleteClick} />
        )}
        {copyClick && <ButtonUI className="bg-primary" text="Másolás" disabled={copyDisabled} onClick={copyClick} />}
      </div>
    </>
  );
};

export default FormHeaderUI;
