import { useState } from 'react';
import { Field } from 'formik';
import DragAndDrop from '../ui/DragAndDrop';
import FormTable from './FormTable';
import { TabsUI, FormLabelUI, FormErrorUI, InputUI } from '../Interface';

const DescriptionPanel = (props) => {
  return (
    <div>
      <div className="col-span-full relative">
        <div className="mt-2">
          <Field
            type="text"
            name="simpleTaskDescription"
            as={InputUI}
            className={`${props.disabled ? 'rounded-md' : ''} resize-none h-36, p-2`}
            fullWidth
            label={<FormLabelUI text="Leírás" />}
            variant="standard"
            validationMessage={<FormErrorUI message={props.errors.simpleTaskDescription} />}
            disabled={props.disabled}
            InputLabelProps={{ shrink: props.values.simpleTaskDescription !== '' }}
            multiline
            rows={4}
          />
        </div>
        <p className="mt-2 leading-6 text-md font-medium text-gray-900">Adja meg a projekthez szükséges leírást.</p>
      </div>
    </div>
  );
};

const FormTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'Leírás',
      content: <DescriptionPanel {...props} />,
    },
    {
      id: 1,
      name: 'Dokumentumok',
      content: props.addField && (
        <DragAndDrop
          SimpleTaskId={props.SimpleTaskId}
          disabled={props.disabled}
          fileList={props.fileList}
          setFileList={props.setFileList}
          newFiles={props.newFiles}
          setNewFiles={props.setNewFiles}
        />
      ),
    },
    {
      id: 2,
      name: 'További felelősök',
      content: props.addField && (
        <FormTable
          projectId={props.projectId}
          simpleTaskId={props.simpleTaskId}
          disabled={props.disabled}
          memberList={props.memberList}
          setMemberList={props.setMemberList}
          setRefetchTable={props.setRefetchTable}
        />
      ),
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
};

export default FormTabs;
