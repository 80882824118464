import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'priority';

const priorityService = {
  getPriority: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/priority`,
      data,
    });
  },

  createPriority: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updatePriority: (data, priorityId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${priorityId}`,
      data,
    });
  },

  createPriorityItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/priorityItem`,
      data,
    });
  },

  deletePriorityItem: (priorityItemId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/priorityItem/${priorityItemId}`,
    });
  },

  updatePriorityItem: (data, priorityId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/priorityItem/${priorityId}`,
      data,
    });
  },

  updateDefaultValue: (id, itemId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/defaultValue/${id}/${itemId}`,
    });
  },
};
export default priorityService;
