import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'app';

const appService = {
  getMenuData: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getMenuData`,
    });
  },

  getUserPermissions: (roleId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getUserPermissions/${roleId}`,
    });
  },

  getRole: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getRole`,
    });
  },

  addRole: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/addRole`,
      data,
    });
  },

  getRoleInformation: (roleId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getRoleInformation?roleId=${roleId}`,
    });
  },

  getUserSubMenuPermissions: (roleId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getUserSubMenuPermissions?roleId=${roleId}`,
    });
  },

  getAllSubMenus: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getAllSubMenus`,
    });
  },

  updateSubMenuPermission: (roleId, selectedSubMenu, permission) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/updateSubMenuPermission?roleId=${roleId}&subMenuId=${selectedSubMenu}&HasPermission=${permission}`,
    });
  },

  getJoinedData: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/getJoinedData`,
      data,
    });
  },

  addItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/addItem`,
      data,
    });
  },

  setItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/setItem`,
      data,
    });
  },

  updateTable: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/updateTable`,
      data,
    });
  },

  deleteItems: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/deleteItems`,
      data,
    });
  },

  getStatus: (menuId) => {
    //@TODO backend
    return request({
      method: Methods.GET,
      resource: `${baseURL}/status/${menuId}`,
    });
  },

  getPriority: (menuId) => {
    //@TODO backend
    return request({
      method: Methods.GET,
      resource: `${baseURL}/priority/${menuId}`,
    });
  },
};

export default appService;
