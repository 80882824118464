import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'auth';

const authService = {
  login: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/login`,
      data,
    });
  },
};

export default authService;
