import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'status';

const statusService = {
  getStatus: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/status`,
      data,
    });
  },

  createStatus: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateStatus: (data, statusId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${statusId}`,
      data,
    });
  },

  createStatusItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/statusItem`,
      data,
    });
  },

  deleteStatusItem: (statusItemId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/statusItem/${statusItemId}`,
    });
  },

  updateStatusItem: (data, statusId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/statusItem/${statusId}`,
      data,
    });
  },

  updateDefaultValue: (id, itemId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/defaultValue/${id}/${itemId}`,
    });
  },

  updateOnKanban: (itemId, onKanban) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/onKanban/${itemId}`,
      data: { onKanban: onKanban },
    });
  },
};

export default statusService;
