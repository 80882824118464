import request from '../utils/Request';

const baseURL = 'masterdata';

const masterDataService = {
  typeToForm: (subMenuId) => {
    return request({
      resource: `${baseURL}/type/${subMenuId}`,
    });
  },

  statusToForm: (subMenuId) => {
    return request({
      resource: `${baseURL}/status/${subMenuId}`,
    });
  },

  priorityToForm: (subMenuId) => {
    return request({
      resource: `${baseURL}/priority/${subMenuId}`,
    });
  },
};

export default masterDataService;
