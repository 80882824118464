import { Route, Navigate } from 'react-router-dom';

export default function ProtectedRoute({ element, ...props }) {
  // Itt ellenőrizzük a bejelentkezett állapotot vagy más jogosultsági feltételeket
  const isAuthenticated = localStorage.getItem('loginStatus'); /* Ide írd be az autentikációs ellenőrzés logikáját */

  if (!isAuthenticated) {
    // Ha nincs bejelentkezve, átirányítjuk a felhasználót a bejelentkezésre
    return <Navigate to="/" />;
  }

  // Ha bejelentkezett, megjelenítjük a védett komponenst
  return element;
}
