import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'taskgantt/taskResources';

const taskGanttService = {
  getTaskResources: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/taskResources`,
    });
  },
};
export default taskGanttService;
