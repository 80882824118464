import { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import Yup from '../../utils/yup';

import DialogUI from '../Interface/DialogUI';
import ButtonUI from '../Interface/ButtonUI';
import InputUI from '../Interface/InputUI';
import projectGroupService from '../../service/projectGroup.service';
import { FormErrorUI, FormLabelUI } from '../Interface';
import { Field, Form, Formik } from 'formik';

export default function AddDialog({
  setprojectGroupList,
  onHide,
  show,
  handleOpen,
  projectGroupStatusId,
  setRefetchTable,
  selectedItem,
}) {
  const [formData, setFormData] = useState({});
  const isEditMode = Object.keys(selectedItem).length > 0;

  const defaultValues = {
    itemName: '',
  };

  const validationSchema = Yup.object().shape({
    itemName: Yup.string().required(),
  });

  useEffect(() => {
    if (isEditMode) {
      setFormData({
        itemName: selectedItem.itemName,
      });
    } else {
      setFormData(defaultValues);
    }
  }, [selectedItem, isEditMode]);

  useEffect(() => {
    setFormData(defaultValues);
  }, []);

  const handleAddProjectGroupItem = async (values) => {
    const insertObject = {
      ...values,
      projectGroupStatusId: projectGroupStatusId,
    };

    try {
      const data = await projectGroupService.createProjectGroupItem(insertObject);
      setprojectGroupList((prevList) => [...prevList, data]);
      onHide();
      setRefetchTable(true);
    } catch (error) {
      console.error('Hiba történt:', error);
    }
  };

  const updateProjectGroupItem = async (values) => {
    try {
      const updatedItem = await projectGroupService.updateProjectGroupItem(values, selectedItem.itemId);
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setprojectGroupList((prevList) =>
        prevList.map((item) => (item.itemId === updatedItem.itemId ? updatedItem : item))
      );
      onHide();
      setRefetchTable(true);
    } catch (error) {
      console.error('Hiba történt:', error);
    }
  };

  const handleCloseDialog = () => {
    onHide();
  };

  const handleSave = (values) => {
    const dbValues = { ...values };
    if (Object.keys(selectedItem).length > 0) {
      updateProjectGroupItem(dbValues);
    } else {
      handleAddProjectGroupItem(dbValues);
    }
  };

  return (
    <DialogUI
      open={show}
      handleOpen={handleOpen}
      headerContent={isEditMode ? 'Elem szerkesztése' : 'Új elem felvitele a listába'}
      fullWidth={true}
    >
      <Formik
        initialValues={formData}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSave(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <div className="relative my-3">
              <div className="mt-2 flex gap-10 items-center justify-center z-50">
                <div className="relative">
                  <div className="relative">
                    <Field
                      type="text"
                      as={InputUI}
                      variant="standard"
                      name="itemName"
                      id="itemName"
                      label={<FormLabelUI text="Megnevezés" />}
                      validationMessage={<FormErrorUI message={errors.itemName} />}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 mt-8 flex justify-center gap-3">
              <ButtonUI
                type="submit"
                text={isEditMode ? 'Mentés' : 'Hozzáadás'}
                className="bg-success"
                disabled={isSubmitting}
              ></ButtonUI>
              <ButtonUI
                text="Mégse"
                className=""
                color="blue-gray"
                variant="gradient"
                onClick={handleCloseDialog}
                disabled={isSubmitting}
              ></ButtonUI>
            </div>
          </Form>
        )}
      </Formik>
    </DialogUI>
  );
}
