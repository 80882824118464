import React from 'react';
import ButtonUI from './ButtonUI';

const FormButtonPanelUI = ({ onSendClick, onCancelClick, disabled }) => {
  return (
    <div className="flex gap-3 justify-center">
      <ButtonUI
        text="Rögzítés"
        className="bg-success flex gap-2 items-center"
        //@TODO remove if formik works on all form
        type={onSendClick ? 'button' : 'submit'}
        onClick={onSendClick}
      >
        Rögzítés
      </ButtonUI>
      <ButtonUI text="Mégse" className="bg-danger flex items-center gap-2" type="button" onClick={onCancelClick}>
        Mégse
      </ButtonUI>
    </div>
  );
};

export default FormButtonPanelUI;
