import React from 'react';
import { Field } from 'formik';

import { InputUI, AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../Interface';

export const FormFields = ({ viewField, submenuId, selectedValues, values, validateField, setFieldValue, errors }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="col-span-4 ">
        <Field
          type="text"
          name="machineName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Gép neve" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.machineName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.machineName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="machineTypeId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Gép csoportja" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.machineTypeId} />}
          onChange={(_e, newVal) => {
            setFieldValue('machineTypeId', newVal?.value ?? null).then(() => {
              validateField('machineTypeId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.machineTypeId !== '' }}
          selectedValue={values.machineTypeId}
          selectedLabelValue={selectedValues?.machines?.machineName}
          table={['ProjectGroupListitems', 'ProjectGroup']}
          joins={'ProjectGroupListitems.projectGroupStatusId = ProjectGroup.projectGroupStatusId'}
          listType={{ id: 'itemId', name: 'itemName' }}
          conditions={`ProjectGroup.subMenuId = ${submenuId}`}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="active"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Aktív" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.active} />}
          onChange={(_e, newVal) => {
            setFieldValue('active', newVal?.value ?? null).then(() => {
              validateField('active');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.active !== '' }}
          selectedValue={values.active}
          selectedLabelValue={selectedValues?.webuserarchive?.arhiveName}
          table={'WebUserArchive'}
          listType={{ id: 'archiveId', name: 'arhiveName' }}
        />
      </div>
    </div>
  );
};
