import axios from 'axios';

import { HttpStatusCode, NotificationType, Methods } from '../config';
import addNotification from './addNotification';

const apiUrl = process.env.REACT_APP_API_URL;

const refreshTokenBlacklist = ['auth/login', 'auth/logout', 'auth/refresh'];

let refreshPromise = null;

const shouldCallRefreshToken = (url) => {
  return url ? !refreshTokenBlacklist.map((path) => url.includes(path)).includes(true) : false;
};

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use((config) => {
  const token = 'store.getState().auth;';

  if (token && config.headers) {
    config.headers['Authorization'] = `bearer ${token}`;
    config.headers['Content-Type'] = 'application/json';
  }

  return config;
});

axios.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (!error.response && error.message) {
      addNotification({
        content: error.message,
        type: NotificationType.ERROR,
      });
    }
    if (error.response && error.config) {
      const { status, data } = error.response;
      const callRefreshToken = shouldCallRefreshToken(error.config.url);

      if (status !== HttpStatusCode.Unauthorized || (status === HttpStatusCode.Unauthorized && !callRefreshToken)) {
        addNotification({
          content: data.error.message,
          type: NotificationType.ERROR,
        });
      }

      if (status === HttpStatusCode.Unauthorized && callRefreshToken) {
        try {
          if (!refreshPromise) {
            // refreshPromise = store.dispatch(refreshToken());
            // @TODO refreshtoken kiolvasása
          }

          await refreshPromise;

          refreshPromise = null;

          return await axios.request(error.config);
        } catch (e) {
          refreshPromise = null;
        }
      }
    }

    return Promise.reject(error);
  }
);

export const isAxiosError = (e) => {
  return Boolean(e?.isAxiosError);
};

const request = async ({
  resource,
  method = Methods.GET,
  transformResponse,
  headers,
  data,
  formData,
  responseType,
  ...requestConfig
}) => {
  const url = `${apiUrl}/${resource}`;

  const { data: response } = await axios({
    method,
    url,
    headers,
    data,
    ...requestConfig,
  });

  return response.data;
};

export default request;
