import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import NoPage from './components/NoPage/NoPage';
import Layout from './components/Navbar/Layout';
import { CompanyForm } from './components/Company/CompanyForm';
import { StatusForm } from './components/Status/StatusForm';
import Auth from './components/Auth/Auth';
import ProtectedRoute from './components/ProtectedRoute';
import RoleIndex from './components/Roles/RoleIndex';
import { SimpleTaskForm } from './components/SimpleTask/SimpleTaskForm';
import { ProjectForm } from './components/Project/ProjectForm';
import { ProjectGroupForm } from './components/ProjectGroupListitems/ProjectGroupForm';
import { PriorityForm } from './components/Priority/PriorityForm';
import { UserForm } from './components/Users/UserForm';
import { MachineForm } from './components/Machines/MachineForm';
import TypeForm from './components/Type/TypeForm';
import MyTodoTable from './components/MyTodo/MyTodoTable';
import { LoaderProvider } from './provider/LoaderProvider';
import LoaderUI from './components/Interface/LoaderUI';
import KanbanForm from './components/Kanban/KanbanForm';
import TaskevaluationForm from './components/TaskEvaluation/TaskevaluationForm';
import TaskGanttForm from './components/TaskGantt/TaskGanttForm';

const App = () => {
  return (
    <LoaderProvider>
      <LoaderUI />
      <SnackbarProvider hideIconVariant>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Auth />} />
            <Route path="/app" element={<ProtectedRoute element={<Layout />} />}>
              <Route index element={<MyTodoTable />} />
              <Route path="project/:id" element={<ProjectForm />} />
              <Route path="company/:id" element={<CompanyForm />} />
              <Route path="status/:id" element={<StatusForm />} />
              <Route path="projectGroup/:id" element={<ProjectGroupForm />} />
              <Route path="Categories/:id" element={<PriorityForm />} />
              <Route path="type/:id" element={<TypeForm />} />
              <Route path="machine/:id" element={<MachineForm />} />

              <Route path="simpleTask/:id" element={<SimpleTaskForm />} />
              <Route path="mytodo/:id" element={<MyTodoTable />} />

              <Route path="user/:id" element={<UserForm />} />
              <Route path="roles/:id" element={<RoleIndex />} />

              <Route path="taskkanban/:id" element={<KanbanForm />} />
              <Route path="taskevaluation/:id" element={<TaskevaluationForm />} />
              <Route path="taskgantt/:id" element={<TaskGanttForm />} />

              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </LoaderProvider>
  );
};

export default App;
