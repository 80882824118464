import React from 'react';
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';

const TabsUI = ({ activeTab, handleTabClick, children }) => {
  return (
    <Tabs value={activeTab} className="w-full ">
      <TabsHeader className={`mx-auto`}>
        {React.Children.map(children, (tab, index) => (
          <Tab key={index} value={index} onClick={() => handleTabClick(index)} className="text-labelColor">
            {tab.props.name}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody
        className="mt-5"
        animate={{
          initial: { y: 250 },
          mount: { y: 0 },
          unmount: { y: 250 },
        }}
      >
        {React.Children.map(children, (tab, index) => (
          <TabPanel key={index} value={index}>
            {tab.props.children}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
};

export default TabsUI;
