import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'user';

const userService = {
  getUsers: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/users`,
      data,
    });
  },

  createUser: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateUser: (data, userId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${userId}`,
      data,
    });
  },

  deleteUser: (userId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${userId}`,
    });
  },
};

export default userService;
