import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Formik, Form } from 'formik';

import Yup from '../../utils/yup';
import statusService from '../../service/status.service';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import { FormHeaderUI, FormButtonPanelUI } from '../Interface';
import FormFields from './FormFields';
import FormTabs from './FormTabs';
import StatusReports from './StatusReports';

export const StatusForm = () => {
  const [refetchTable, setRefetchTable] = useState(true);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [statusList, setstatusList] = useState([]);

  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultValues, selectedValues)) {
        setstatusList(selectedValues?.statusItems ?? []);
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        valuesToForm();
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const defaultValues = {
    selectListTitle: '',
    subMenuId: null,
  };

  const validationSchema = Yup.object().shape({
    selectListTitle: Yup.string().required(),
    subMenuId: Yup.string().required(),
  });

  const valuesToForm = () => {
    const { selectListTitle, subMenuId } = selectedValues;

    setInitValues({
      selectListTitle,
      subMenuId,
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
    };

    statusService.updateStatus(updData, selectedValues.statusId).then((_status) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setSelectedValues(defaultValues);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
      valuesToForm();
    });
  };

  const handleInsertObject = (values) => {
    const insertObject = {
      ...values,
    };

    statusService
      .createStatus(insertObject)
      .then(() => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setSelectedValues(defaultValues);
        setRefetchTable(true);
        setEditButtonvisible(true);
        setAddField(true);
        setViewField(true);
        valuesToForm();
      })
      .finally(() => {
        //@TODO loader
      });
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues(defaultValues);
              setSelectedValues({});
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
              setstatusList([]);
            }}
            addDisabled={!viewField}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
            //validateOnMount={false}
          >
            {({ isSubmitting, values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <FormFields
                  viewField={viewField}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                />
                {/*<FormFields values={{ initValues, setInitValues }} viewField={viewField} submenuId={id} />*/}

                {addField && (
                  <FormTabs
                    statusId={selectedValues?.statusId}
                    disabled={viewField}
                    statusList={statusList}
                    setstatusList={setstatusList}
                    setRefetchTable={setRefetchTable}
                    viewField={viewField}
                    selectedValues={selectedValues}
                  />
                )}

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <StatusReports
        refetch={{ refetchTable, setRefetchTable }}
        values={{ selectedValues, setSelectedValues }}
        viewField={viewField}
        operatorsDisabled={operatorsDisabled}
      />
    </div>
  );
};
