import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';

import { useLoader } from '../../provider/LoaderProvider';
import taskGanttService from '../../service/taskGantt.service';
import TodoDialog from '../MyTodo/TodoDialog';

const TaskGanttForm = () => {
  const { showLoader, hideLoader } = useLoader();
  const [tasks, setTasks] = useState([]);
  const [taskEvents, setTaskEvents] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    getTaskResources();
  }, []);

  const getTaskResources = () => {
    showLoader();
    taskGanttService
      .getTaskResources()
      .then((data) => {
        const transformedData = data.reduce((acc, task) => {
          const { machineId, machineName, machineGroup } = task.machine;
          const groupId = machineGroup ? machineGroup.itemId : 'default';
          const groupTitle = machineGroup ? machineGroup.itemName : 'Nincs csoport';

          if (!acc[groupId]) {
            acc[groupId] = {
              id: groupId,
              title: groupTitle,
              children: [],
            };
          }

          acc[groupId].children.push({
            id: machineId,
            title: machineName,
          });

          return acc;
        }, {});

        setTasks(Object.values(transformedData));

        const events = data.map((task) => ({
          id: task.machine.machineName,
          title: task.simpleTaskName,
          start: task.startDate,
          responsibePerson: task.responsiblePerson?.userName,
          end: task.endDate,
          resourceId: task.machine.machineId,
        }));

        setTaskEvents(events);
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <div>
      <FullCalendar
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        initialView="resourceTimelineDay"
        resources={tasks}
        groupResourceTypes={true}
        groupByResource={true}
        locales={[huLocale]}
        locale="hu"
        events={taskEvents}
        slotLabelFormat={[
          { month: 'long', year: 'numeric', weekday: 'long' },
          { hour: 'numeric', minute: '2-digit' },
        ]}
        editable={true}
        eventResizableFromStart={true}
        eventResizableFromEnd={true}
        eventClick={() => {
          setDialogOpen(true);
        }}
        eventContent={(eventInfo) => (
          <div>
            <div>{eventInfo.event.title}</div>
            <div className="responsible">
              <p>
                Felelős:
                {eventInfo.event.extendedProps.responsibePerson ? eventInfo.event.extendedProps.responsibePerson : '-'}
              </p>
            </div>
          </div>
        )}
        headerToolbar={{
          left: 'prev next today',
          center: 'title',
          right: 'resourceTimelineDay resourceTimelineWeek resourceTimelineMonth',
        }}
        views={{
          resourceTimelineDay: {
            slotLabelFormat: [{ hour: 'numeric', minute: '2-digit' }],
          },
          resourceTimelineWeek: {
            slotLabelFormat: [
              { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' },
              { hour: 'numeric', minute: '2-digit' },
            ],
          },
          resourceTimelineMonth: {
            slotLabelFormat: [{ day: 'numeric', weekday: 'long' }],
          },
        }}
      />
      <TodoDialog
        open={dialogOpen}
        task={taskEvents}
        handleClose={() => {
          setDialogOpen(false);
        }}
      />
    </div>
  );
};

export default TaskGanttForm;
